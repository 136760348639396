import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
const firebaseConfig = {
  apiKey: 'AIzaSyCm5LVuaBsfCKF5P0Ok46aFlwNh4zTbc6M',
  authDomain: 'qamous-blog.firebaseapp.com',
  projectId: 'qamous-blog',
  storageBucket: 'qamous-blog.appspot.com',
  messagingSenderId: '222299519996',
  appId: '1:222299519996:web:22d2f565ced17f4a58d079',
  measurementId: 'G-434XST6YF3'
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);
export { db };
